import Scroll from './Scroll';
// import Parallax from './parallax';
import Menu from './menu';

$(function ($) {
  new Menu();
  new Scroll();

  let $jsSlider = $('.js_mainSlide');
  $jsSlider.slick({
    autoplay: true, //「オプション名: 値」の形式で書く
    dots: true, //複数書く場合は「,」でつなぐ
    arrows: false,
    autoplaySpeed: 5000,
    speed: 1000,
    autoplay: true
  });

  let $jsSliderBrand = $('.js-brandSlider');
  $jsSliderBrand.slick({
    autoplay: true, //「オプション名: 値」の形式で書く
    dots: true, //複数書く場合は「,」でつなぐ
    arrows: false,
    autoplaySpeed: 5000,
    speed: 1000,
    autoplay: true
  });


  let $jsImgSlide = $('.js_imgSlide');
  $jsImgSlide.slick({
    autoplay: true, //「オプション名: 値」の形式で書く
    dots: true, //複数書く場合は「,」でつなぐ
    arrows: false,
    autoplaySpeed: 5000,
    speed: 1000,
    autoplay: true
  });

  let $jsImghistorySlider = $('.js_imghistorySlider');
  $jsImghistorySlider.slick({
    autoplay: true, //「オプション名: 値」の形式で書く
    dots: true, //複数書く場合は「,」でつなぐ
    arrows: false,
    autoplaySpeed: 5000,
    speed: 1000,
    autoplay: true
  });

  $('.js-dropDown').on('click', () => {
    $('.js-dropDown').toggleClass('is-active');
  })

  let $jsMessageSlider = $('.js-missionSlide');
  $jsMessageSlider.slick({
    autoplay: true, //「オプション名: 値」の形式で書く
    dots: false, //複数書く場合は「,」でつなぐ
    arrows: false,
    autoplaySpeed: 1000,
    speed: 1000,
    autoplay: true
  });

  let check=setInterval(()=>{
    const hashTxt= location.hash;
    if(hashTxt=='#bl_formThank'){
      $('#bl_formThank').show();
      clearInterval(check);
    }
  },1000)

  //   //メニュー
  //   let $menuWrapper = $('.js-menuWrapper');
  //   let $menuSwitch = $('.js-menuSwitch')

  //   $menuSwitch.on('click', (e) => {
  //     if (!$menuSwitch.hasClass('is-active')) {
  //       $menuSwitch.addClass('is-active');
  //       $menuWrapper.addClass('is-active');
  //     } else {
  //       $menuSwitch.removeClass('is-active');
  //       $menuWrapper.removeClass('is-active');
  //     }
  //   })
  //   $menuWrapper.find('a').on('click', () => {
  //     $menuWrapper.removeClass('is-active');
  //     $menuSwitch.removeClass('is-active');
  //   })

  //   $('.js-menuAc').on('click', (e) => {
  //     let target = $(e.currentTarget);
  //     target.toggleClass('is-active');
  //   })
  //   //スライダー
  //   let $jsSlider = $('.js-slider');
  //   $jsSlider.slick();
  //   $jsSlider.on('init reInit afterChange', function (event, slick, currentSlide) {
  //     let target = $('.js-slickIndex[href="#' + currentSlide + '"]');
  //     target.closest('.js_worksListUnit').find('li').removeClass('is-active');
  //     target.closest('li').addClass('is-active');
  //   });

  //   let $jsSlickIndex = $('.js-slickIndex');

  //   $jsSlickIndex.on('click', (e) => {
  //     e.preventDefault();
  //     let $target = $(e.currentTarget);
  //     $jsSlickIndex.closest('li').removeClass('is-active');
  //     $target.closest('li').addClass('is-active');
  //     let index = $target.attr('href').replace(/#/, '');
  //     $jsSlider.slick('slickGoTo', index, true);
  //   })

  //   let jsSliderFirstItem = $('.js-slickIndex').closest('.is-active').find('a');
  //   if (jsSliderFirstItem.length > 0) {
  //     let firstIndex = jsSliderFirstItem.attr('href').replace(/#/, '');
  //     $jsSlider.slick('slickGoTo', firstIndex, true);
  //   }
  //   //マップスライダー
  //   let $sliderMap = $('.js-sliderMap');
  //   $sliderMap.slick({
  //     adaptiveHeight: true
  //   });

  //   $sliderMap.on('init reInit afterChange', function (event, slick, currentSlide) {
  //     $('.bl_mapsBoxList li:nth-child(' + (currentSlide + 1) + ')').addClass('is-active').siblings('li').removeClass('is-active');
  //     $('.js-pointer[href="#' + currentSlide + '"]').addClass('is-active').siblings().removeClass('is-active');
  //   });

  //   $('.js-pointer,.bl_mapsBoxList a').on('click', (e) => {
  //     let $target = $(e.currentTarget);
  //     let index = $target.attr('href').replace(/#/, '');
  //     $sliderMap.slick('slickGoTo', index, true);
  //     return false;
  //   })

  //   //スクロール
  //   let scrill = new Scroll();
  //   let hash = location.hash;
  //   if (hash != '') {
  //     setTimeout(() => {
  //       scrill.move(hash);
  //     }, 500)
  //   }

  //   $('.js_worksListUnit').on('click', (e) => {
  //     scrill.move(e.currentTarget);
  //   })
  // });

  // //ローディングアニメーション
  // let lottieObj = lottie.loadAnimation({
  //   container: document.getElementById('bl_loading'),
  //   renderer: 'svg',
  //   loop: true,
  //   autoplay: false, // 自動再生させないように
  //   path: '/saiyo/anime/data.json'
  // });

  // lottieObj.play();

  // var p1 = new Promise((resolve, reject) => {
  //   window.onload = () => {
  //     resolve("load");
  //   }
  // });

  // var p2 = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve("limit");
  //   }, 3000);
  // });

  // Promise.all([p1, p2]).then(values => {
  //   lottieObj.stop();
  //   $('#bl_loading').fadeOut(200);

});
